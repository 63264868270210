/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Col, Form, Input, Modal, notification, Radio, Row, Select, Typography } from 'antd';
import { connect } from 'react-redux';
import { get, reduce } from 'lodash';
import { addAdminEquipChecks, deleteAdminEquipChecks, updateAdminEquipChecks } from '../../redux/admin/checks/action';

const { Text } = Typography;

const AddNewChecks = (props) => {

    const {
        setChecksModalOpen,
        checksModalOpen,
        checksModalText,
        checksUpdate,
        checksDelete,
        checksForm,
        checksText,
        setChecksText,
        typeOfMaintenanceId,
        equipmentTypeId,
        equipmentTypeName,
        checkIntervalId,
        checksId,
        setChecksId,
        setChecksModalText,
        setIsPhotoRequired,
        isPhotoRequired,
        expectedOutput,
        setExpectedOutput,
        isValue, setValue,
        isYesorNoFeild, setYesOrNoFeild,
        isThumpsUp, setThumpsUp,
        setUniqueIdentifier, uniqueIdentifier

    } = props;

    const {
        onAddEquipCheck,
        isAddAdminEquipChecksLoading,
        isAdminEquipChecksDeleteLoading,
        isAdminEquipChecksUpdateLoading,
        onUpdateEquipCheck,
        onDeleteEquipCheck
    } = props;

    const expectedOutputsArray = [
        {
            id: "value",
            name: 'Value'
        },
        {
            id: "yesorno",
            name: 'Yes / No'
        },
        {
            id: "thumpsUp",
            name: 'ThumpsUp / ThumpsDown'
        }
    ];


    useEffect(() => {
        const fetchData = async () => {
            try {
                await new Promise(resolve => setTimeout(resolve, 0));
                const data = await checksForm.validateFields();
                if (data.checksText) {
                    setChecksText(data.checksText);
                }
                setExpectedOutput(data.checked);
            } catch (error) {
                console.error("Error occurred while validating fields:", error);
            }
        };
        fetchData();
    }, []);



    /** Waiting to Add Check and close Modal */
    useEffect(() => {
        if (!isAddAdminEquipChecksLoading) {
            setChecksModalOpen(false);
            checksForm.resetFields();
            setChecksText('');
            setUniqueIdentifier('');
        }
    }, [isAddAdminEquipChecksLoading]);

    /** Waiting to Update check and close Modal */
    useEffect(() => {
        if (!isAdminEquipChecksUpdateLoading) {
            setChecksModalOpen(false);
            checksForm.resetFields();
            setChecksText('');
            setUniqueIdentifier('');
            setExpectedOutput([])
        }
    }, [isAdminEquipChecksUpdateLoading]);

    /** Waiting to Delete check and close Modal */
    useEffect(() => {
        if (!isAdminEquipChecksDeleteLoading) {
            setChecksModalOpen(false);
            checksForm.resetFields();
            setChecksText('');
            setUniqueIdentifier('');
        }
    }, [isAdminEquipChecksDeleteLoading]);

    /** Adding or Updating interval */
    const handleOk = () => {
        checksForm
            .validateFields().then(async (data) => {
                if (checksUpdate) {
                    if (data.checksText && data.uniqueIdentifier && data.checked.length) {
                        var obj = {
                            obj: {
                                "checkIntervalId": checkIntervalId,
                                "typeOfMaintenanceId": typeOfMaintenanceId,
                                // "equipmentTypeId": equipmentTypeId,
                                "pihEquipmentType": equipmentTypeName,
                            },
                            data: {
                                equipmentTypeCheck: {
                                    "check": data.checksText,
                                    "inputField": isValue,
                                    "yesOrNoField": isYesorNoFeild,
                                    "isCheckedField": isThumpsUp,
                                    "photosRequired": isPhotoRequired,
                                    'uniqueIdentifier': data.uniqueIdentifier,
                                }
                            },
                            id: checksId
                        }
                        // console.log('Successfully Updated check',obj,expectedOutput,isPhotoRequired)
                        await onUpdateEquipCheck(obj);
                    } else {
                        notification.warning({
                            message: 'Please edit or enter the Equipment Check to Update!'
                        });
                    }
                } else if (checksDelete) {
                    var obj = {
                        data: {
                            "checkIntervalId": checkIntervalId,
                            "typeOfMaintenanceId": typeOfMaintenanceId,
                            // "equipmentTypeId": equipmentTypeId,
                            "pihEquipmentType": equipmentTypeName,
                        },
                        id: checksId
                    }
                    await onDeleteEquipCheck(obj);
                }
                else {
                    if (data.checksText && data.uniqueIdentifier && data.checked.length) {
                        // if (isPhotoRequired) {
                        var obj = {
                            "pihEquipmentTypeCheck": {
                                "check": data.checksText,
                                "checkIntervalId": checkIntervalId,
                                "typeOfMaintenanceId": typeOfMaintenanceId,
                                "equipmentTypeId": equipmentTypeId,
                                "inputField": isValue,
                                "yesOrNoField": isYesorNoFeild,
                                "isCheckedField": isThumpsUp,
                                "photosRequired": isPhotoRequired,
                                'uniqueIdentifier': data.uniqueIdentifier,
                                "pihEquipmentType": equipmentTypeName,
                            }
                        }
                        // console.log('Successfully added check',obj,expectedOutput,isPhotoRequired);
                        await onAddEquipCheck(obj);
                        // }
                        // else { notification.warning({ message: 'Please check Photos Required to proceed' }) }
                    }
                    else {
                        notification.warning({
                            message: 'Please enter the required value to proceed!',
                            placement: 'topRight',
                        });
                    }
                }
            });


    }

    /** Resetting values on Closing Modal */
    const handleCancel = () => {
        setChecksModalOpen(false);
        checksForm.resetFields();
        setChecksText('');
        setUniqueIdentifier('');
        setChecksId('');
        setChecksModalText('');
        setIsPhotoRequired()
        setValue()
        setYesOrNoFeild()
        setThumpsUp(1)
        setExpectedOutput([])
        checksForm.resetFields();
    }

    /**Function to set radio button on user selection */
    const onChangedIsPhotoRequired = (e) => {
        setIsPhotoRequired(e.target.value)
    }

    /**Function to set Expected out on user selection */
    const onChangeExpectedOutput = (e) => {
        setExpectedOutput(e);
        setValue(e.some(element => element.includes('Value')) ? 1 : 0)
        setYesOrNoFeild(e.some(element => element.includes('Yes / No')) ? 1 : 0)
        setThumpsUp(e.some(element => element.includes('ThumpsUp / ThumpsDown')) ? 1 : 0)
    }

    return (
        <div>
            <Modal
                width="50vw"
                className="pipeline-modal"
                title={checksModalText}
                centered
                visible={checksModalOpen}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        {'Cancel'}
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleOk}
                        loading={isAddAdminEquipChecksLoading || isAdminEquipChecksDeleteLoading || isAdminEquipChecksUpdateLoading}
                    >
                        {checksUpdate ? (isAdminEquipChecksUpdateLoading ? 'Updating' : 'Update') : (checksDelete ? (isAdminEquipChecksDeleteLoading ? 'Deleting' : 'Confirm') : (isAddAdminEquipChecksLoading ? 'Adding' : 'Add'))}
                    </Button>
                ]}
                // zIndex={5000000}
            >
                <Form
                    form={checksForm}
                    name="basic"
                    layout="vertical"
                    autoComplete="off"
                    getContainer={false}
                >
                    <Row gutter={24}>
                        {!checksDelete ?
                            <Col lg={18}>
                                <Form.Item
                                    label="Equipment Check"
                                    name="checksText"
                                    required
                                >
                                    <Input
                                        placeholder={checksUpdate ? 'Edit selected Equipment Check ' : 'Enter New Equipment Check'}
                                        value={checksText}
                                        onChange={(e) => setChecksText(e.target.value)}
                                        size="large"
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Unique Identifier"
                                    name="uniqueIdentifier"
                                    required
                                >
                                    <Input
                                        placeholder={checksUpdate ? 'Edit selected Unique Identifier' : 'Enter Unique Identifier'}
                                        value={uniqueIdentifier}
                                        onChange={(e) => setUniqueIdentifier(e.target.value)}
                                        size="large"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name='checked'
                                    label="Expected Output"
                                    required
                                >
                                    <Select className='maintenance-select'
                                        placeholder='Select One Or More Expected Outputs'
                                        mode='multiple'
                                        style={{
                                            width: '100%',
                                        }}
                                        value={expectedOutput}
                                        defaultValue={'ThumpsUp / ThumpsDown'}
                                        onChange={onChangeExpectedOutput}
                                        getPopupContainer={(triggerNode) => triggerNode.parentNode}

                                    >
                                        {expectedOutputsArray && expectedOutputsArray.map((item, i) =>
                                            <Select.Option 
                                            // disabled={item && item.name === 'ThumpsUp' ? true : false}
                                                key={i} value={item && item.name} >{item && item.name} </Select.Option>)}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label='Photos Required'
                                    className='report-radio'
                                    name='photoRequired'
                                >
                                    <Radio.Group onChange={onChangedIsPhotoRequired} value={isPhotoRequired} style={{ display: "inline-flex" }}>
                                        <Radio value={1}>Yes</Radio>
                                        <Radio value={2}>No</Radio>
                                        {expectedOutput?.includes('Yes / No') ? <Radio value={3}>Is expected output is Yes</Radio> : null}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            :
                            <div style={{ padding: '0 16px' }}>
                                <Text style={{ fontSize: '16px' }}><p>Deleting Equipment Check will delete all the corresponding information.</p>Are you sure you want to delete <b style={{ color: "#EF314C" }}>{checksText}</b> Equipment Check?</Text>
                            </div>
                        }
                    </Row>
                </Form>
            </Modal>
        </div>
    );
};

const mapStateToProps = ({ adminEquipChecksReducer }) => {
    const isAddAdminEquipChecksLoading = get(adminEquipChecksReducer, 'isAddAdminEquipChecksLoading', false);
    const isAdminEquipChecksUpdateLoading = get(adminEquipChecksReducer, 'isAdminEquipChecksUpdateLoading', false);
    const isAdminEquipChecksDeleteLoading = get(adminEquipChecksReducer, 'isAdminEquipChecksDeleteLoading', false);
    return {
        isAddAdminEquipChecksLoading,
        isAdminEquipChecksUpdateLoading,
        isAdminEquipChecksDeleteLoading,
    }
}

const mapDispatchToProps = {
    onAddEquipCheck: addAdminEquipChecks,
    onUpdateEquipCheck: updateAdminEquipChecks,
    onDeleteEquipCheck: deleteAdminEquipChecks,
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNewChecks);